import Draggable, {DraggableCore} from 'react-draggable';
import CloseButton from '../basic/CloseButton';
import { useEffect, useRef, useState } from 'react';



// props = canvas_width, canvas_height
export default function AddColonyOverlay({width_bound, height_bound, place_colony_clicked_func, new_colony_input_updated_func, remove_function, overlay_id}) {
    
    const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
    const [color, setColor] = useState('#2563eb');
    
    const overlay_ref = useRef(null)



    useEffect( () => {
        if(overlay_ref.current){
            // subtracting the width/height of this overlay, so the bounds work properly
            const rect = overlay_ref.current.getBoundingClientRect();
            setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
        }
    },[]);
    

    const handle_place_colony_clicked = () => {
        const new_colony_data = {
            colony_name: document.getElementById('colony_name_input').value,
            colony_color: document.getElementById('colony_color_picker').value
        }

        place_colony_clicked_func(new_colony_data)

    }

    const handle_colony_input_updated = (event) => {
        const new_colony_data = {
            colony_name: document.getElementById('colony_name_input').value,
            colony_color: document.getElementById('colony_color_picker').value
        }
        setColor(new_colony_data.colony_color);
        new_colony_input_updated_func(new_colony_data)
    }

    return(
    <div className=" bg-black" >
        <Draggable
            bounds={bounds}
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            grid={[1, 1]}
            scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            >

            <div className="absolute bg-blue  " ref={overlay_ref}>
                <div className="relative handle bg-white h-5 opacity-90">
                    <CloseButton close_function={remove_function} overlay_id={overlay_id}></CloseButton>
                </div>
                <div className="bg-slate-700 opacity-85 ">
                <h1 className="text-2xl text-white font-bold" >Colony Creation:</h1>

                <div className="flex flex-col items-end gap-6 w-72 m-5">
                    <div className="relative w-full min-w-[200px] h-10">
                        <input
                            onChange={handle_colony_input_updated}
                            className="peer w-full h-full  text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 border-t-transparent focus:border-t-transparent text-sm px-3 py-2.5 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
                            id="colony_name_input"
                            placeholder="" 
                        />
                        <label
                            className="flex w-full h-full select-none pointer-events-none absolute left-0 font-normal !overflow-visible truncate peer-placeholder-shown:text-blue-gray-500 leading-tight peer-focus:leading-tight peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500 transition-all -top-1.5 peer-placeholder-shown:text-sm text-[11px] peer-focus:text-[11px] before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t-2 before:border-l peer-focus:before:border-l-2 before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t-2 after:border-r peer-focus:after:border-r-2 after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-gray-900 before:border-blue-gray-200 peer-focus:before:!border-gray-900 after:border-blue-gray-200 peer-focus:after:!border-gray-900">
                            Colony Name
                        </label>
                        <input id="colony_color_picker" type="color" 
                            className="p-1 h-10 block bg-white border border-gray-200 cursor-pointer rounded-lg disabled:opacity-50 disabled:pointer-events-none " 
                            value= {color} 
                            onChange={handle_colony_input_updated}
                            title="Choose your color">
                        </input>

                    </div>
                    <button type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 "
                        onClick={handle_place_colony_clicked}>
                        Place Colony
                    </button>

                </div>
                </div>
                
            </div>
        </Draggable>
        
      </div>    
    );

}