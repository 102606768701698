import React, { useEffect, useRef, useState } from "react";
import BaseButtons from "./BaseButtons";
import ColonyOverlay from "./overlays/ColonyOverlay";
import OverlayComponents from "./overlays/OverlayComponents";
import default_map_image from "../assets/world_map.png";
import Selector from "./Selector.jsx";
import Module from "./simulation.js";


export default function Main() {

  // const [simulation, setSimulation] = useState(null);
  // const [startSimulationSelected, setStartSimulationSelected] = useState(false); // determines state of start/stop button
  // const [mapMode, setMapMode] = useState("political");
  // const [simulationIsRunning, setSimulationIsRunning] = useState(false); // determines if c++ loop is going

  const SELECTOR_MODES = {
    COLONY_OVERLAY: "colony_overlay",
    NEW_COLONY_PLACEMENT: "new_colony_placement",
    EDIT_COLONY_BORDERS: "edit_colony_borders",
  }

  const simulationRef = useRef(null);
  const startSimulationSelectedRef = useRef(false);
  // const mapModeRef = useRef("political");
  const simulationIsRunningRef = useRef(false);
  const selectorRef = useRef( new Selector(SELECTOR_MODES.COLONY_OVERLAY))
  const disableBaseButtonsRef = useRef(true);


  const instanceRef = useRef(null);
  
  var references = {
    overlayComponents: useRef(),
  }
 
  useEffect(() => {
    const c = document.getElementById("canvas");
    const ctx = c.getContext("2d");
    // const simulation_container = c.getContext("simulation_container");

    // // Add event listener to handle window resize
    // window.addEventListener("resize", updateCanvasSize);

    const initializeInstance = async () => {
      const { default: factory } = await import('./simulation.js');
      instanceRef.current = await factory();


      
      // promt user with a new simulation overlay
      add_new_simulation_overlay(false);
      
      // adding the default image to the canvas and converting it to a simulation map
      // create_default_sim_map(instanceRef);



    };

    // setting simulation container to fit the user's screen
    update_simulation_container();

    initializeInstance();
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };

  }, []);
  
  useEffect( () => {

    updateCanvasSize() // doesnt really belong here
    // update_canvas_variables();

  }, )

  const create_custom_sim_map = (tile_size, num_of_colonies, imgdata) => {

    const reader = new FileReader();
    reader.onload = function(event) {
        const img = new Image();
        img.src = event.target.result;
        img.onload = function() {
            // get the size of the image
            const img_width = img.width;
            const img_height = img.height;

            
            // find the ratio
            const aspect_ratio = img_width / img_height;
            let new_width; 
            let new_height;

            // gets the simulation container and canvas
            const sim_container = document.getElementById("simulation_container");
            const canvas = document.getElementById("canvas");
            const context = canvas.getContext("2d");




            if (aspect_ratio > 1) {
              // wider than tall
              new_width = Math.min(sim_container.clientWidth, img_width);
              new_height =   new_width / aspect_ratio;
            } else {
              // taller than wide
              new_height = Math.min(sim_container.clientHeight, img_height);
              new_width = new_height * aspect_ratio;
            }

            /// Now ensure the new dimensions fit within the container's dimensions
            
            // new width is greater than the container's width, so change accordingly
            if (new_width > sim_container.clientWidth) {

              new_width = sim_container.clientWidth;
              new_height = new_width / aspect_ratio;
            }

            // new height is greater than the container's height, so change accordingly
            if (new_height > sim_container.clientHeight) {
              new_height = sim_container.clientHeight;
              new_width = new_height * aspect_ratio;
            }

            // floor both sizes
            new_width = Math.floor(new_width)
            new_height = Math.floor(new_height)


            
            // set canvas to proper size regarding the image and user's screen
            canvas.width = new_width;
            canvas.height = new_height;


            context.drawImage(img, 0, 0, new_width, new_height);


            // now create a new simulation (Also I know this isnt the most efficient way to do this, but it only does it once so its fine)
            const sim = new instanceRef.current.Simulation(tile_size, num_of_colonies);

            
            sim.set_map_mode("political");
            simulationRef.current = sim;

            simulationRef.current.set_map_mode("political");
            simulationRef.current.run_simulation();
            simulationRef.current.draw_all_tiles();


        };
     };

    reader.readAsDataURL(imgdata);
    
  }


  const create_default_sim_map = (tile_size, num_of_colonies) => {

    
    const img = new Image();
    img.src = default_map_image;
    img.onload = function() {
      // get the size of the image
      const img_width = img.width;
      const img_height = img.height;

      
      // find the ratio
      const aspect_ratio = img_width / img_height;
      let new_width; 
      let new_height;

      // gets the simulation container and canvas
      const sim_container = document.getElementById("simulation_container");
      const canvas = document.getElementById("canvas");
      const context = canvas.getContext("2d");





      if (aspect_ratio > 1) {
        // wider than tall
        new_width = Math.min(sim_container.clientWidth, img_width);
        new_height =   new_width / aspect_ratio;
      } else {
        // taller than wide
        new_height = Math.min(sim_container.clientHeight, img_height);
        new_width = new_height * aspect_ratio;
      }

      /// Now ensure the new dimensions fit within the container's dimensions
      
      // new width is greater than the container's width, so change accordingly
      if (new_width > sim_container.clientWidth) {

        new_width = sim_container.clientWidth;
        new_height = new_width / aspect_ratio;
      }

      // new height is greater than the container's height, so change accordingly
      if (new_height > sim_container.clientHeight) {
        new_height = sim_container.clientHeight;
        new_width = new_height * aspect_ratio;
      }

      // floor both sizes
      new_width = Math.floor(new_width)
      new_height = Math.floor(new_height)


      
      // set canvas to proper size regarding the image and user's screen
      canvas.width = new_width;
      canvas.height = new_height;


      context.drawImage(img, 0, 0, new_width, new_height);


      // now create a new simulation (Also I know this isnt the most efficient way to do this, but it only does it once so its fine)
      const sim = new instanceRef.current.Simulation(tile_size, num_of_colonies);

      
      sim.set_map_mode("political");
      simulationRef.current = sim;

      simulationRef.current.set_map_mode("political");
      simulationRef.current.run_simulation();
      simulationRef.current.draw_all_tiles();

      // simulationRef.current.set_map_mode("resource_iron_ore");

    }
  }






  const update_simulation_container = () => {
    const buttonsHeight = document.getElementById("buttons").clientHeight;
    const titleHeight = document.getElementById("title").clientHeight;
    const sim_container = document.getElementById("simulation_container");
    sim_container.style.height = `${window.innerHeight  - buttonsHeight - titleHeight}px`
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const updateCanvasSize = () => {
    //TODO canvas width/height will depend on the selected map
    const sim_container = document.getElementById("simulation_container");
    const canvas = document.getElementById("canvas");
  
    // canvas.height = sim_container.clientHeight-10;

    if (simulationRef.current != null) {
      const width = simulationRef.current.get_sim_width(); // note, this is getting total pixels, not tiles
      const height = simulationRef.current.get_sim_height();
  
      canvas.width = width;
      canvas.height = height;

      // TODO draw_all_tiles()
      // TODO change to draw the correct one 
      // simulation.simulate_one_step("terrain");

      // simulationRef.current.draw_all_tiles_with_mode("political");
      simulationRef.current.draw_all_tiles();

      // simulation.start_simulation();
      // simulation.run_simulation("political");

      
    //   simulation.update_canvas_vars();
    }

  };


  //  ** Overlays  ** //
  
  // adds a colonyOverlay component to the screen
  const add_colony_overlay = () => {
    references.overlayComponents.current.add_colonyOverlay_func(place_colony_clicked, new_colony_input_updated);
    // add_new_simulation_overlay();
  }

  const place_colony_clicked = (new_colony_data) => {
    selectorRef.current.set_selector_mode(SELECTOR_MODES.NEW_COLONY_PLACEMENT);
    selectorRef.current.update_new_colony_data(new_colony_data); // tells selector to use data from this overlay
    
  }

  const new_colony_input_updated = (new_colony_data) => {
    selectorRef.current.update_new_colony_data(new_colony_data);
  }

  const add_new_simulation_overlay = (show_close_button) => {
    
    references.overlayComponents.current.add_NewSimulationOverlay_func(show_close_button);
  }

  const add_new_colony_view_overlay = async(x,y) => {
    try {
      const colony_data = {}
      // returns -1 if no colony is found
      simulationRef.current.get_colony_id_at_canvas_cords(x, y, colony_data);
  
      if (colony_data.id != -1) {
        references.overlayComponents.current.add_NewColonyViewOverlay_func(colony_data.id);
      }

    } catch (error) {
        console.error("Error getting colony id", error); // Handle any errors that occur during the promise resolution
    }
  }


async function async_sleep() {
    await sleep(2000); // Sleep for 2000 milliseconds (2 seconds)
}

  /**
   * @param {number} tile_size 
   * @param {number} number_colonies
   * @param {string} map_type // "world_map" or "custom_map"
  **/
  const handle_create_new_simulation_func = async (tile_size, number_colonies, map_type, img) => {

    // check if there is a current simulation running
    if(simulationRef.current != null){
      // TODO delete this entire simulation first
      simulationRef.current.tell_main_loop_to_stop();
      // simulationRef.current = null;
      await sleep(2000);
      simulationRef.current.delete_simulation();
     

    }

    // tell selctor to go to colony view
    selectorRef.current.set_selector_mode(SELECTOR_MODES.COLONY_OVERLAY);
    // reset booleans simulation is not running and has not started!
    simulationIsRunningRef.current = false; 
    startSimulationSelectedRef.current = false;
    disableBaseButtonsRef.current = true; // disable the buttons until the simulation is created (aka like 10 lines from now)

    // then create a new simulation

    if (map_type == "world_map") {
      create_default_sim_map(tile_size, number_colonies);
    }else{
      // TODO create a simulation with a custom map
      create_custom_sim_map(tile_size, number_colonies, img);
    }

    disableBaseButtonsRef.current = false; // dont disable the buttons anymore
    
    // close all of the overlays
    references.overlayComponents.current.close_all_overlays_func();
  
    
  }


  // handles when overlay requests updated info for its colony
  const handle_update_colony_view_overlay_func = async (id) => {
    const colony_overlay_data = {}
    
    simulationRef.current.get_colony_overlay_data_from_id(id, colony_overlay_data);
    
    return colony_overlay_data;
  }

  const send_to_discord_func = () => {
    window.location.href = "https://discord.gg/EUGWKK96";
  }

  const base_btn_functions = {
    simulate: handleSimulate,
    add_colony_func:add_colony_overlay,
    send_to_discord_func,
  }


  function handleSimulate () {


    if(check_disable_func() == true){
      return;
    }

    if (simulationIsRunningRef.current == true && simulationRef.current != null) {

      if (startSimulationSelectedRef.current == true) {
        // then stop it
        simulationRef.current.stop_simulation();
        startSimulationSelectedRef.current = false;
      }else{
        // then start it
        simulationRef.current.start_simulation();
        startSimulationSelectedRef.current = true;
      }
    } else {
      // simulation thread has not started
      simulationRef.current.start_simulation();
      // simulationRef.current.run_simulation();
      simulationIsRunningRef.current = true;
      startSimulationSelectedRef.current = true;
    }
    // simulation.stop_simulation();
    // run_simulation();
  }

  const overlay_functions ={
    handle_create_new_simulation_func,
    handle_update_colony_view_overlay_func,

  }

  const set_map_mode_terrain = () => {simulationRef.current.set_map_mode("terrain"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_political = () => {simulationRef.current.set_map_mode("political"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_resource_wood = () => {simulationRef.current.set_map_mode("resource_wood"); simulationRef.current.draw_all_tiles()} 
  const set_map_mode_resource_iron_ore = () => {simulationRef.current.set_map_mode("resource_iron_ore"); simulationRef.current.draw_all_tiles()} 
  const set_map_mode_resource_food = () => {simulationRef.current.set_map_mode("resource_food"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_resource_coal = () => {simulationRef.current.set_map_mode("resource_coal"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_frontline = () => {simulationRef.current.set_map_mode("frontline"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_troop = () => {simulationRef.current.set_map_mode("troop"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_supply = () => {simulationRef.current.set_map_mode("supply"); simulationRef.current.draw_all_tiles()}

  const set_map_mode_regions = () => {simulationRef.current.set_map_mode("region"); simulationRef.current.draw_all_tiles()} 
  const set_map_mode_population = () => {simulationRef.current.set_map_mode("population"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_colony_control = () => {simulationRef.current.set_map_mode("colony_control"); simulationRef.current.draw_all_tiles()}
  const set_map_mode_cultures = () => {simulationRef.current.set_map_mode("culture"); simulationRef.current.draw_all_tiles()}


  /// Colony Draw Editor:

  // called when "Draw!" clicked on edit colony borders overlay
  const edit_colony_borders = (set_selector_to_edit,colony_id, slider_value) => {

    // tell selector the mode has changed
    if(set_selector_to_edit){
      selectorRef.current.set_selector_mode(SELECTOR_MODES.EDIT_COLONY_BORDERS);
    }else{
      selectorRef.current.set_selector_mode(SELECTOR_MODES.COLONY_OVERLAY);
    }
    // store data from this edit colony borders overlay
    const edit_colony_borders_data = {
      colony_id: colony_id,
      radius: slider_value,

    }
    selectorRef.current.update_edit_colony_borders_data(edit_colony_borders_data);
  }

  // opens up a new overlay to edit/draw colony borders
  const add_colony_border_editor = () => {
    const list_of_colonies = {}
    simulationRef.current.get_list_of_colonies(list_of_colonies);

    references.overlayComponents.current.add_EditColonyBordersOverlay_func(list_of_colonies,edit_colony_borders);
  }

  // this actually tells the simulation to place a colony at the tile
  const tell_simulation_to_place_colony_at_cord = async(x,y) => {
    simulationRef.current.place_colony_at_canvas_cords(x,y, selectorRef.current.edit_colony_borders_data.colony_id, selectorRef.current.edit_colony_borders_data.radius);
  }


  /// map mode overlay 
  const add_map_mode_overlay = () => {
    references.overlayComponents.current.add_MapModeOverlay_func(set_map_mode_functions_for_overlay)
  }

  // called when a map mode is selected (not used atm since we call functions directly)
  const add_mapModeOverlay_handle_func = (map_mode) => {
    simulationRef.current.set_map_mode(map_mode);
  }


  /// Stats Overlay

  // called when stats button presses
  const add_statsOverlay = () => {
    // TODO maybe initial stats here?
    references.overlayComponents.current.add_StatsOverlay_func(update_statsOverlay)
  }
  

  const update_statsOverlay = async() => {
    const ledger_data = {}

    // getting ledger data from simulation
    simulationRef.current.get_ledger_data(ledger_data);

    return ledger_data;
  }

  const stats_button_functions = [
    add_statsOverlay,

  ]



  const set_map_mode_functions_for_overlay = {
    resource_food : set_map_mode_resource_food,
    resource_wood : set_map_mode_resource_wood,
    resource_iron_ore : set_map_mode_resource_iron_ore,
    resource_coal : set_map_mode_resource_coal,
    military_troops : set_map_mode_troop,
    military_control : set_map_mode_colony_control,
    military_supply : set_map_mode_supply,
    military_terrain : set_map_mode_terrain,
    political : set_map_mode_political,
    population : set_map_mode_population,
    regions : set_map_mode_regions,
    frontline : set_map_mode_frontline,
    political : set_map_mode_political,
    cultures : set_map_mode_cultures,


  }
    
  
  // list that is sent to base buttons
  const edit_button_functions = [
    add_colony_border_editor

  ]

  // New^
  // const open_new_simulation_overlay = () => {
  //   handle_create_new_simulation_func();
  // }

  const set_map_mode_functions = [
    set_map_mode_political,
    set_map_mode_troop,

    // set_map_mode_frontline,
    // set_map_mode_population,
 
    // set_map_mode_regions,
    add_map_mode_overlay,
    
  ]

 
  /*
    set_map_mode_resource_food,
    set_map_mode_resource_wood,
    set_map_mode_resource_iron_ore,
    set_map_mode_resource_coal,
  */
  
  const new_button_functions = [
    () => add_new_simulation_overlay(true) // true == show close button
  ]

  
  const add_functions = [
    add_colony_overlay
  ]


  const handle_slider_input_func = (slider_input) => {
    slider_input = parseInt(slider_input);
    simulationRef.current.set_simulation_speed(slider_input);
  }
 

  const check_disable_func = () => {
    return disableBaseButtonsRef.current;
  }

 const handle_mouse_canvas_click = (event) => {
    const canvas = document.getElementById("canvas");
    var rect = canvas.getBoundingClientRect();

    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    // simulationRef.current.print_tile_at_canvas_cords(x,y);

    switch(selectorRef.current.get_selector_mode()){
      case SELECTOR_MODES.NEW_COLONY_PLACEMENT:
        // place a new colony
        // simulationRef.current.place_colony(x, y, selectorRef.current.new_colony_data.colony_color);
        simulationRef.current.place_custom_colony(x,y, selectorRef.current.new_colony_data.colony_name ,selectorRef.current.new_colony_data.colony_color);
        simulationRef.current.draw_all_tiles();
        selectorRef.current.set_selector_mode(SELECTOR_MODES.COLONY_OVERLAY);
        break;
      case SELECTOR_MODES.COLONY_OVERLAY:
        // open the colony overlay
        add_new_colony_view_overlay(x,y);
        break;
      case SELECTOR_MODES.EDIT_COLONY_BORDERS:
        tell_simulation_to_place_colony_at_cord(x,y);
        break;
    }

 }


 /*
  MOUSE AND CANVAS INTERACTIONS
 */
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    let isDragging = false;
    let prevX, prevY;

    const handleMouseDown = (event) => {
      const canvas = document.getElementById("canvas");
      var rect = canvas.getBoundingClientRect();

      isDragging = true;
      prevX = event.clientX - rect.left;
      prevY = event.clientY - rect.top;
    };

    const handleMouseMove = (event) => {
      const canvas = document.getElementById("canvas");
      var rect = canvas.getBoundingClientRect();

      if (isDragging) {
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;
        // Implement your dragging logic here using the ctx context
        
        switch(selectorRef.current.get_selector_mode()){
          case SELECTOR_MODES.EDIT_COLONY_BORDERS:
            tell_simulation_to_place_colony_at_cord(x,y);
            break;
        }

        prevX = x;
        prevY = y;
      }
    };

    const handleMouseUp = () => {
      isDragging = false;
    };

    canvas.addEventListener('mousedown', handleMouseDown);
    canvas.addEventListener('mousemove', handleMouseMove);
    canvas.addEventListener('mouseup', handleMouseUp);

    
    return () => {
      canvas.removeEventListener('mousedown', handleMouseDown);
      canvas.removeEventListener('mousemove', handleMouseMove);
      canvas.removeEventListener('mouseup', handleMouseUp);
    };

  }, []);

 
 const handle_mouse_canvas_dragged = (event) => {

  const canvas = document.getElementById("canvas");
  var rect = canvas.getBoundingClientRect();

  const x = event.clientX - rect.left;
  const y = event.clientY - rect.top;

  switch(selectorRef.current.get_selector_mode()){
    case SELECTOR_MODES.EDIT_COLONY_BORDERS:
      tell_simulation_to_place_colony_at_cord(x,y);
  }
  }

 const CanvasComponent = React.memo(() => {
  return  (<canvas
  id="canvas"
  width="100%"
  height="85%"
  onClick={handle_mouse_canvas_click}
  onDrag={handle_mouse_canvas_dragged}
  onMouseDown={handle_mouse_canvas_dragged}
  ref={canvasRef}
  className="flex-shrink-0 bg-green-400"
>
</canvas>
  )

});

  return (
    <div className="flex flex-col flex-grow-1">

      {/* <button className="bg-white" onClick={add_colony_overlay}>Add Colony Overlay</button> */}
      {/* <button className="bg-slate-300" onClick={handleCreateSimulation}>create simulation</button>*/}
      {/* <button className="bg-white" onClick={handleSimulate}>Simulate</button>  */}
      <div id="simulation_container" className="flex ">
      


        {/* {overlayComponents} */}
        <OverlayComponents overlay_functions={overlay_functions}  ref={references.overlayComponents} />
        <div className="flex w-full h-full items-center justify-center">
          <CanvasComponent></CanvasComponent>
        </div>
      </div>
      
        <div id="buttons" className="h-12">
          <BaseButtons check_disable_func={check_disable_func} base_btn_funcs={base_btn_functions} set_map_mode_functions={set_map_mode_functions} add_functions={add_functions}
            slider_input_func={handle_slider_input_func} new_button_functions={new_button_functions} edit_button_functions={edit_button_functions} stats_button_functions={stats_button_functions}/>
        </div>
      
    </div>
  );
}
