
import Draggable, {DraggableCore} from 'react-draggable';
import CloseButton from '../basic/CloseButton';

import { useEffect, useRef, useState } from 'react';

export default function EditColonyBordersOverlay({width_bound, height_bound, remove_function, overlay_id, handle_function, list_of_colonies}) {

  const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
  const [default_position, setDefaultPosition] = useState({x: 0, y: 0})
  
  const [selectedColonyId, setSelectedColonyId] = useState(0);
  const [sliderValue, setSliderValue] = useState(3);
  const [drawColonyName, setDrawColonyName] = useState('');
  const overlay_ref = useRef(null)


  useEffect( () => {
    if(overlay_ref.current){
      // subtracting the width/height of this overlay, so the bounds work properly
      const rect = overlay_ref.current.getBoundingClientRect();
      setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
      setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
    }
  },[]);

  const handle_close_button_clicked = () => {
    update_main_on_edit(false); //  we want to set selector to (default)
    remove_function(overlay_id);
  }

  // called when "Draw!" button is clicked
  const handle_draw_clicked = () => {
    update_main_on_edit(true); //  we want to set selector to edit
    setDrawColonyName(list_of_colonies[selectedColonyId]);
  }

  const update_main_on_edit = (set_selector_to_edit) =>{

    handle_function(set_selector_to_edit,selectedColonyId, parseInt(sliderValue));
  }

  const handle_select_change = (event) => {
    setSelectedColonyId(parseInt(event.target.value)); 
    
  }

  const select_box = () => {
    return(
      <div className='text-black'>
        <label className='text-white' for="colonies">Select a colony to draw: </label>
        <select
        className='w-full bg-white text-black rounded-md p-1'
        onChange={handle_select_change}
        >
        {Object.keys(list_of_colonies).map(key => {
          return(
            <option value={key}>{list_of_colonies[key]}</option>
          )
        })
          
        }
        </select>
    </div>
  );
  }

  const handle_slider_change = (event) => {
    setSliderValue(event.target.value);
  }

  return (
    <div>
           <Draggable
            bounds={bounds}
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={null}
            grid={[1, 1]}
            scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            >

            <div className="absolute bg-blue  " ref={overlay_ref}>
                <div className="relative handle bg-white h-5 opacity-90">
                    <CloseButton close_function={handle_close_button_clicked} overlay_id={overlay_id}></CloseButton>
                </div>
                <div className="bg-slate-700 opacity-95 text-white ">
                    <h1 className="text-2xl text-white font-bold px-2" >Draw Colony Borders</h1>
                    {select_box()}
                    <div className="mx-1">
                      <label for="draw_size" className="text-white block text-sm font-medium text-gray-900 dark:text-white">Draw Size: {sliderValue}</label>
                      <input id="draw_size" onChange={handle_slider_change} type="range" min="1" max="20" value={sliderValue} step="1" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"></input>
                  </div>
                  <button className="my-1 bg-white text-slate-700 font-bold rounded-md w-[100%]" onClick={handle_draw_clicked}>Draw {drawColonyName}!</button>

                </div>
                

                
                
            </div>
        </Draggable>
    </div>
  );
}