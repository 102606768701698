import { LineChart, PieChart, Cell,Pie, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function ColonyViewPopulation({colony_data, previous_colony_data, list_colony_data }) {
    

    const rgb_to_hex = (r, g, b) => {
        const to_hex = (c) => {
          const hex = c.toString(16);
          return hex.length === 1 ? '0' + hex : hex; // Add leading zero if necessary
        };
      
        return '#' + to_hex(r) + to_hex(g) + to_hex(b);
      }

    const pi_data = []
    const COLORS = []

    for (const [key, value] of Object.entries(colony_data.culture_pop_distribution)) {
        var new_insert = {}
        new_insert['name'] = key;
        new_insert['value'] = value[0]
        pi_data.push(new_insert)
        COLORS.push(rgb_to_hex(value[1], value[2], value[3]))
    }

    const show_population_graph = () => {
        const RADIAN = Math.PI / 180;
        const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
          const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);
        
          return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
              {`${(percent * 100).toFixed(0)}%`}
            </text>
          );
        };

        return(
        <div style={{ width:'40vh', height: '40vh' }}> 
        <ResponsiveContainer width="100%" height="50%">
            <LineChart
                data={list_colony_data.population_list}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis/>
                <YAxis />
                <Tooltip/>
                <Legend />
                <Line type="monotone" dataKey="population" stroke="#5CCE95" activeDot={{ r: 1 }} />
            </LineChart>

            
        </ResponsiveContainer>
     


        <ResponsiveContainer width="100%" height="50%">
        <PieChart>
                <Pie
                    data={pi_data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    // label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    stroke="none"
                    animationBegin={0}
                    animationDuration={0}
                >
                    {pi_data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip />
            </PieChart>
        </ResponsiveContainer>


        </div>
        )
    }
    return (
        <div className='text-white'>
            <h1>Population:</h1>
            <h1>Population = {colony_data.population}</h1>
            
            {show_population_graph()}
        </div>
    );
}
