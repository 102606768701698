import Draggable, {DraggableCore} from 'react-draggable';
import CloseButton from '../basic/CloseButton';
import { useEffect, useRef, useState } from 'react';


// props = canvas_width, canvas_height
export default function NewSimulationOverlay({width_bound, height_bound, remove_function, overlay_id, handle_function, show_close_button},) {
    const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
    const [default_position, setDefaultPosition] = useState({x: 0, y: 0})

    const [tileSize, setTileSize] = useState(1); // Initial value
    const [numberColonies, setNumberColonies] = useState(20); // Initial value
    const [selectedFile, setSelectedFile] = useState(null); 
    const [mapType, setMapType] = useState("world_map"); 

    // const imageInput = document.getElementById('imageInput');
    // imageInput.addEventListener('change', handle_custom_map_selected);   

    const overlay_ref = useRef(null)

    useEffect( () => {
        if(overlay_ref.current){
            // subtracting the width/height of this overlay, so the bounds work properly
            const rect = overlay_ref.current.getBoundingClientRect();
            setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
            setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
        }
    },[]);

    const hand_file_input_change = (event) => {
        setSelectedFile(event.target.files[0]);
        setMapType("custom_map");
        // document.getElementById('world_map').checked = false;
        // document.getElementById('custom_map').checked = true; 
    }

    const handle_custom_map_selected = (event) => {
        setMapType("custom_map");
    }

    const handle_create_new_simulation_clicked = () => {

        // const tile_size = parseInt(document.getElementById('tile_size').value);
        // const number_colonies = parseInt(document.getElementById('number_colonies').value);
        const tile_size = parseInt(tileSize);
        const number_colonies = parseInt(numberColonies);
        

        // default map
        if (document.getElementById('world_map').checked) {
            handle_function(tile_size, number_colonies, "world_map");
        }else{
            if (selectedFile === null) {
                alert("Please upload an image to use as a custom map");
                return;
            }
            handle_function(tile_size, number_colonies, "custom_map", selectedFile);
   
        }
    }

    const handle_num_colonies_data_change = (event) => {
        

        let filtered_value = event.target.value.replace(/[+-]/g, '');
        if (filtered_value === "") {
            filtered_value = 0;
        }else if (isNaN(filtered_value)){
            filtered_value = 0;
        }

        filtered_value = parseInt(filtered_value);
        if (filtered_value < 0) {
            setNumberColonies(0);
        }else if (filtered_value > 5000) {
            setNumberColonies(5000);
        }else{
            setNumberColonies(filtered_value);
        }
    }

    const handle_tile_size_data_change = (event) => {
        if (event.target.value < 1) {
            setTileSize(1);
        } else if (event.target.value > 10) {
            setTileSize(10);
        }else{
            setTileSize(event.target.value);
        }

    }

    const handle_world_map_selected = (event) => {
        setMapType("world_map");
    }
     
    
    return(
    <div className=" bg-black" >
        <Draggable
            bounds={bounds}
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={default_position}
            grid={[1, 1]}
            scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            >

            <div className="absolute bg-blue  " ref={overlay_ref}>
                {show_close_button && 
                    <div className="relative handle bg-white h-5 opacity-95">
                        <CloseButton close_function={remove_function} overlay_id={overlay_id}></CloseButton>
                    </div>
                }
                <div className="bg-slate-700 opacity-95 ">
                    <h1 className="text-2xl text-white font-bold px-2" >Create New Simulation</h1>

                    <div className="flex flex-col items-center gap-6 m-5">
                        <div className="relative w-full min-w-[200px] ">
                        
                            {/* TODO: add create new Simulation input */}
                            <div className='grid grid-col-1'>
                                <label className='text-white' htmlFor="tile_size">Tile Size: </label>
                                <input id='tile_size' type='number' 
                                    className='rounded-sm'
                                    min={1}
                                    max={50}
                                    value={tileSize}
                                    onChange={handle_tile_size_data_change}
                                    onPaste={null}
                                ></input>

                                <label className='text-white' htmlFor="tile_size">Number Colonies: </label>
                                <input id='number_colonies' type='number' 
                                    className='rounded-sm'
                                    min={0}
                                    max={5000}
                                    value={numberColonies}
                                    onChange={handle_num_colonies_data_change}
                                    onPaste={null}
                                ></input>

                                <label className='text-white ' htmlFor="">Select Map: </label>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input id="world_map" type="radio" value="world_map" name="radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        onClick={handle_world_map_selected}
                                        checked={mapType === "world_map"}/>
                                    <label htmlFor="world_map" className="w-full py-4 ms-2 text-sm font-medium text-white">World Map</label>
                                </div>
                                <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                                    <input onClick={handle_custom_map_selected} id="custom_map" type="radio" value="custom_map" name="radio"
                                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        checked={mapType === 'custom_map'}/>
                                    <label htmlFor="custom_map" className="w-full py-4 ms-2 text-sm font-medium text-white">Custom Map Img</label>
                                    <input type="file" id="imageInput" onChange={hand_file_input_change} accept="image/*"/>

                                </div>
                        
                            </div>

                        </div>

                        <div className="flex justify-center">
                            <button onClick={handle_create_new_simulation_clicked} type="button" className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 justify-center">Create New Simulation!</button>
                        </div>
                    </div>
                </div>
                
            </div>
        </Draggable>
        
      </div>    
    );

}