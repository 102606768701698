import Draggable, {DraggableCore} from 'react-draggable';
import { useEffect, useRef, useState } from 'react';
import CloseButton from '../basic/CloseButton';

export default function MapModeOverlay({width_bound, height_bound,remove_function, overlay_id, map_mode_functions}) {

    const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
    const overlay_ref = useRef(null)
    const [mapMode, setMapMode] = useState("");


    useEffect( () => {
        if(overlay_ref.current){
          // subtracting the width/height of this overlay, so the bounds work properly
          const rect = overlay_ref.current.getBoundingClientRect();
          setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
        }
        // handle_wood_clicked();

      },[]);



      const handle_wood_clicked = () => {
        setMapMode("wood");
        map_mode_functions.resource_wood();
      }

        const handle_iron_clicked = () => {
            setMapMode("iron_ore");
            map_mode_functions.resource_iron_ore();
        }

        const handle_coal_clicked = () => {
            setMapMode("coal");
            map_mode_functions.resource_coal();
        }

        const handle_food_clicked = () => {
            setMapMode("food");
            map_mode_functions.resource_food();
        }

        const handle_troops_clicked = () => {
            setMapMode("troops");
            map_mode_functions.military_troops();
        }

        const handle_control_clicked = () => {
            setMapMode("control");
            map_mode_functions.military_control();
        }

        const handle_supply_clicked = () => {
            setMapMode("supply");
            map_mode_functions.military_supply();
        }

        const handle_terrain_clicked = () => {
            setMapMode("terrain");
            map_mode_functions.military_terrain();
        }

        const military_radio_buttons = () => {
            return(
                <div className=''>
                    <label className='text-white font-bold m-3' htmlFor="">Military: </label>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="troops" type="radio" value="troops" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_troops_clicked}
                            checked={mapMode === "troops"}/>
                        <label htmlFor="troops" className="w-full py-4 ms-2 text-sm font-medium text-white">Troops</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="control" type="radio" value="control" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_control_clicked}
                            checked={mapMode === "control"}/>
                        <label htmlFor="control" className="w-full py-4 ms-2 text-sm font-medium text-white">Control</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="supply" type="radio" value="supply" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_supply_clicked}
                            checked={mapMode === "supply"}/>
                        <label htmlFor="supply" className="w-full py-4 ms-2 text-sm font-medium text-white">Supply</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="terrain" type="radio" value="terrain" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_terrain_clicked}
                            checked={mapMode === "terrain"}/>
                        <label htmlFor="terrain" className="w-full py-4 ms-2 text-sm font-medium text-white">Terrain</label>
                    </div>

                    


                </div>

            );
            
        }

        const resource_radio_buttons = () => {
            return(
                <div className=''>

                <label className='text-white font-bold m-3' htmlFor="">Resources: </label>
                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="wood" type="radio" value="wood" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_wood_clicked}
                            checked={mapMode === "wood"}/>
                        <label htmlFor="wood" className="w-full py-4 ms-2 text-sm font-medium text-white">Wood</label>
                    </div>
                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input onClick={handle_iron_clicked} id="iron_ore" type="radio" value="iron_ore" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={mapMode === 'iron_ore'}/>
                        <label htmlFor="iron_ore" className="w-full py-4 ms-2 text-sm font-medium text-white">Iron Ore</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input onClick={handle_coal_clicked} id="coal" type="radio" value="coal" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={mapMode === 'coal'}/>
                        <label htmlFor="coal" className="w-full py-4 ms-2 text-sm font-medium text-white">Coal</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input onClick={handle_food_clicked} id="food" type="radio" value="food" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            checked={mapMode === 'food'}/>
                        <label htmlFor="food" className="w-full py-4 ms-2 text-sm font-medium text-white">Food</label>
                    </div>

                </div>

            );
        }

        const handle_pop_clicked = () => {
            setMapMode("pop");
            map_mode_functions.population();
        }

        const handle_regions_clicked = () => {
            setMapMode("regions");
            map_mode_functions.regions();
        }

        const handle_culture_clicked = () => {
            setMapMode("culture");
            map_mode_functions.cultures();
        }

        const handle_political_clicked = () => {
            setMapMode("political");
            map_mode_functions.political();
        }

        const main_radio_buttons = () => {
            return(
                <div className=''>

                <label className='text-white font-bold m-3' htmlFor="">Main: </label>
                    
                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="political" type="radio" value="political" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_political_clicked}
                            checked={mapMode === "political"}/>
                        <label htmlFor="political" className="w-full py-4 ms-2 text-sm font-medium text-white">Political</label>
                    </div>
                </div>
            );
        }

        const population_radio_buttons = () => {

            return(
                <div className=''>

                <label className='text-white font-bold m-3' htmlFor="">Population: </label>
                    
                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="pop" type="radio" value="pop" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_pop_clicked}
                            checked={mapMode === "pop"}/>
                        <label htmlFor="pop" className="w-full py-4 ms-2 text-sm font-medium text-white">Population</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="regions" type="radio" value="regions" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_regions_clicked}
                            checked={mapMode === "regions"}/>
                        <label htmlFor="regions" className="w-full py-4 ms-2 text-sm font-medium text-white">Regions</label>
                    </div>

                    <div className="flex items-center ps-4 border border-gray-200 rounded dark:border-gray-700">
                        <input id="culture" type="radio" value="culture" name="radio"
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            onClick={handle_culture_clicked}
                            checked={mapMode === "culture"}/>
                        <label htmlFor="culture" className="w-full py-4 ms-2 text-sm font-medium text-white">Culture</label>
                    </div>


                </div>
            );
        }

return (
    <div className=" bg-black" >
        <Draggable
         bounds={bounds}
         axis="both"
         handle=".handle"
         defaultPosition={{x: 0, y: 0}}
         position={null}
         grid={[1, 1]}
         scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            >
            <div className="absolute bg-blue  "  ref={overlay_ref}>
                <div className="relative handle bg-white h-5 opacity-95">
                    <CloseButton close_function={remove_function} overlay_id={overlay_id}></CloseButton>
                </div>
                <div className="bg-slate-700 opacity-95 text-white"  style={{ maxHeight: height_bound, overflowY: 'auto' }}>
                    <h1 className='font-bold mx-4' >Select Map Mode</h1>
                    
                    {main_radio_buttons()}
                    {population_radio_buttons()}
                    {military_radio_buttons()}
                    {resource_radio_buttons()}
                    
                </div>
            </div>
        </Draggable>
        
      </div>    
);

}