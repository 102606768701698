import Draggable, {DraggableCore} from 'react-draggable';
import { useEffect, useRef, useState } from 'react';
import CloseButton from '../basic/CloseButton';

export default function StatsOverlay({width_bound, height_bound, remove_function, overlay_id, update_function}) {

    const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
    const overlay_ref = useRef(null)
    const [statsData, setStatsData] = useState({});
    const [showLedgerType, setShowLedgerType] = useState("colony");

    const update_ledger_data = async() => {

         try{
            // get new stats data, and update current text
            let data = await update_function();
            // if(data.sim_running == true){
            //     // only add to lists if the simulation is running
            //     add_colony_data_to_list(data);
            // }

            setStatsData(data);
            // currentCDRef.current = data;

        }catch(error){
            console.log(`Error updating stats overlay`);
        }
    }


    useEffect( () => {
        if(overlay_ref.current){
            // subtracting the width/height of this overlay, so the bounds work properly
            const rect = overlay_ref.current.getBoundingClientRect();
            // setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
            setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
            update_ledger_data();
            
            const interval_id = setInterval(update_ledger_data, 1000);
            return () => clearInterval(interval_id);
        }
    },[]);


    const colony_stats = () => {
        if(statsData.colony_data == undefined){
            return(
                <h2>Loading...</h2>
            );
        }
        return(
            <div >
                {/* <h1 className='font-bold'>Colony Stats</h1> */}
                <table className='table-auto'>
                    <thead >
                        <tr>
                            <th className='px-4 py-2'>Colony Name</th>
                            <th className='px-4 py-2'>Tiles</th>
                            <th className='px-4 py-2'>Population</th>
                            <th className='px-4 py-2'>Active Troops</th>
                            <th className='px-4 py-2'>Troops Killed</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        Object.keys(statsData.colony_data).map((key) => {
                                
                                return(
                                    <tr>
                                    <td>{statsData.colony_data[key].name}</td>
                                    <td>{statsData.colony_data[key].tile_count}</td>
                                    <td>{statsData.colony_data[key].population}</td>
                                    <td>{statsData.colony_data[key].active_troops}</td>
                                    <td>{statsData.colony_data[key].troops_killed}</td>
                                    </tr>

                                );
                            })
                        }
                    </tbody>
                </table>

            </div>

        );
    }


    const culture_stats = () => {
        if(statsData.colony_data == undefined){
            return(
                <h2>Loading...</h2>
            );
        }
        return(
            <div >
                {/* <h1 className='font-bold'>Colony Stats</h1> */}
                <table className='table-auto'>
                    <thead >
                        <tr>
                            <th className='px-4 py-2'>Culture Name</th>
                            <th className='px-4 py-2'>Wood Bonus</th>
                            <th className='px-4 py-2'>Iron Bonus</th>
                            <th className='px-4 py-2'>Coal Bonus</th>
                            <th className='px-4 py-2'>Food Bonus</th>

                            <th className='px-4 py-2'>Military Supply Bonus</th>
                            <th className='px-4 py-2'>Food Supply Bonus</th>
                            <th className='px-4 py-2'>Shipbuilding Bonus</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        Object.keys(statsData.culture_data).map((key) => {
                                
                                return(
                                    <tr>
                                    <td>{statsData.culture_data[key].name}</td>
                                    <td>{(statsData.culture_data[key].wood_mod).toFixed(2)}</td>
                                    <td>{(statsData.culture_data[key].iron_ore_mod).toFixed(2)}</td>
                                    <td>{(statsData.culture_data[key].coal_mod).toFixed(2)}</td>
                                    <td>{(statsData.culture_data[key].food_mod).toFixed(2)}</td>

                                    <td>{(statsData.culture_data[key].military_supplies_production_mod).toFixed(2)}</td>
                                    <td>{(statsData.culture_data[key].military_food_supplies_production_mod).toFixed(2)}</td>
                                    <td>{(statsData.culture_data[key].shipbuilding_supplies_production_mod).toFixed(2)}</td>
                                    </tr>

                                );
                            })
                        }
                    </tbody>
                </table>

            </div>

        );
    }

    const update_bounds = () => {
        const rect = overlay_ref.current.getBoundingClientRect();
        // setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
        setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
    }

    useEffect( () => {
        update_bounds();

    },[showLedgerType]);


    const handle_colony_clicked = () => {
        setShowLedgerType("colony");
    }

    const handle_culture_clicked = () => {
        setShowLedgerType("culture");
    }



    return(
        <div className=" bg-black" >
        <Draggable
         bounds={bounds}
         axis="both"
         handle=".handle"
         defaultPosition={{x: 0, y: 0}}
         position={null}
         grid={[1, 1]}
         scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            >
            <div className="absolute bg-blue  "  ref={overlay_ref}>
                <div className="relative handle bg-white h-5 opacity-95">
                    <CloseButton close_function={remove_function} overlay_id={overlay_id}></CloseButton>
                </div>
                <div className="bg-slate-700 opacity-95 text-white"  style={{ maxHeight: height_bound-20, overflowY: 'auto', maxWidth: width_bound, overflowX: 'auto' }}>
                    <h1 className='font-bold mx-4' >Simulation Stats: </h1>
                    <div className='flex'>
                        <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                            onClick={handle_colony_clicked}>
                            Colony
                        </button>
                        <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                            onClick={handle_culture_clicked}>
                            Culture
                        </button>
                    </div>
                    {showLedgerType=="colony" && 
                        colony_stats()
                    }
                    {showLedgerType=="culture" && 
                        culture_stats()
                    }
                    
                </div>
            </div>
        </Draggable>
        
      </div> 
    );

}