export default class Selector{

    selector_mode;

    new_colony_data = {
        colony_name: "default",
        colony_color: [0,0,0,255] 
    };

    edit_colony_borders_data = {
        colony_id: 0,
        radius: 0 // x by x pixels
    }

    constructor(selector_mode){
        this.selector_mode = selector_mode;
    }

    set_selector_mode = (mode) => {
        this.selector_mode = mode;
    }

    get_selector_mode = () => {
        return this.selector_mode;
    }   

    update_new_colony_data = (new_colony_data) => {
        this.new_colony_data = new_colony_data;
    }

    update_edit_colony_borders_data = (edit_colony_borders_data) => {
        this.edit_colony_borders_data = edit_colony_borders_data;
    }
    
}