import React, { forwardRef, useImperativeHandle, useState } from "react";
import ExpandedButton from "./ExpandedButton";
import BaseButton from "./BaseButton";

// props = [text, button_texts , function_calls]
const ExpandingButton = forwardRef(({text, button_texts, function_calls, close_btns_func, check_disable_func}, ref) => {
    const [isExpanded, setExpanded] = useState(false);


    // allows us to pass back data to the Parent component (Buttons.js)
    useImperativeHandle(ref, () => {
      return {
        close_button: set_expanded_to_false,
      };
    });

    const set_expanded_to_false = () => {
      setExpanded(false);
    } 

    const toggleAccordion = () => {
        close_btns_func() // close all buttons
        setExpanded(!isExpanded);
    };

    return (
      <>       

            <div className="relative">

            {isExpanded && (
              <div className="flex absolute top-[-115%] h-12">
                  
                  {
                    button_texts.map((btn_text, i) => (
                      <ExpandedButton check_disable_func={check_disable_func} key={btn_text} text={btn_text} function_call={function_calls[i]}/>
                    ))    
                  }

              </div>
            )}
          </div>

          <BaseButton text={text} onclick_func={toggleAccordion}/>

      </>
      );
  });

  export default ExpandingButton;