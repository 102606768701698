import React, { useRef } from "react";
import BaseButton from "./BaseButton";
import ExpandingButton from "./ExpandingButton";
import SliderInput from "./SliderInput";

// will pass in functions to call (from main) for each Button
export default function Buttons({check_disable_func, base_btn_funcs, set_map_mode_functions, add_functions, slider_input_func, new_button_functions, edit_button_functions, stats_button_functions}) {


    const button_ref = useRef();

    const base_buttons_references = {
        add_btn: useRef(),
        edit_btn: useRef(),
        mapView_btn: useRef(),
        stats_btn: useRef(),
        new_btn: useRef(),
    }

    const close_all_buttons = () => {
        for(const [keyboard, value] of Object.entries(base_buttons_references)){
            value.current.close_button();
        }
    }
    

  return (
    <div className="flex h-full">

        <BaseButton text="Play" onclick_func={base_btn_funcs.simulate}/>
        <SliderInput check_disable_func={check_disable_func} handle_func={slider_input_func}></SliderInput>


        <ExpandingButton check_disable_func={check_disable_func} ref={base_buttons_references.new_btn} text="New^" button_texts = {["Image"]} function_calls={new_button_functions} close_btns_func={close_all_buttons} /> 
        <ExpandingButton check_disable_func={check_disable_func} ref={base_buttons_references.add_btn} text="Add^" button_texts={["Colony"]} function_calls={add_functions} close_btns_func={close_all_buttons}/>
        <ExpandingButton check_disable_func={check_disable_func} ref={base_buttons_references.edit_btn} text="Edit^" button_texts={["Colony Borders"]} function_calls={edit_button_functions} close_btns_func={close_all_buttons}/>
        <ExpandingButton check_disable_func={check_disable_func} ref={base_buttons_references.mapView_btn} text="Map Views^" button_texts = {["Political","Troops", "More^"]} function_calls={set_map_mode_functions} close_btns_func={close_all_buttons} /> 
        <ExpandingButton check_disable_func={check_disable_func} ref={base_buttons_references.stats_btn} text="Stats^" button_texts = {["Ledger"]} close_btns_func={close_all_buttons} function_calls={stats_button_functions}/> 

        <BaseButton text="Discord" onclick_func={base_btn_funcs.send_to_discord_func} />
        {/* <BaseButton text="Support" /> */}
        {/* <BaseButton text="Settings" /> */}




        {/* {base_buttons.map((button) => (
            button.expand ? 
            ( // expanding button
                <div key={button.id}>
                    <ExpandingButton text={button.text} button_texts={button.button_texts} ></ExpandingButton> 
                </div>
            )
            :
            ( // basic button
                <div key={button.id}>
                    <Button text={button.text}></Button>
                </div>
            )
        ))} */}

    
    </div>
  );
}
