import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default function ColonyViewIndustry({colony_data, previous_colony_data, list_colony_data}){

    const show_industry_graphs = () => {
        return (
            <div style={{ width:'40vh', height: '40vh' }}> {/* Set the height of the parent container */}

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={[...list_colony_data.industry_list]}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={"military_supplies"} stroke="#6AB672" activeDot={{ r: 1 }} />
                    <Line type="monotone" dataKey={"military_food_supplies"} stroke="#778ED7" activeDot={{ r: 1 }} />
                    <Line type="monotone" dataKey={"shipbuilding_supplies"} stroke="#B4BF6F" activeDot={{ r: 1 }} />
                    
                </LineChart>
            </ResponsiveContainer>
            </div>

        );
    }

    return (
        <div className="text-white">
            <h1 className="font-bold">Industry:</h1>
            <h1>Population: {colony_data.population}</h1>
            <h1>Military Supplies: {colony_data.military_supplies}</h1>
            <h1>Military Food Supplies: {colony_data.military_food_supplies}</h1>
            <h1>Shipbuilding Supplies: {colony_data.shipbuilding_supplies}</h1>
            {show_industry_graphs()}
        </div>
    )
    
    };