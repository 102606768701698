import Main from "./components/Main";
import Title from "./components/Title";

function App() {
  return (
    <div className="bg-slate-800 h-screen flex flex-col">
      <Title />
      <Main />
    </div>
  );
}

export default App;
