import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { useState } from 'react';

export default function ColonyViewMilitary({colony_data, previous_colony_data, list_colony_data}){
 
    
    const show_military_graphs = () => {
        return (
            <div style={{ width:'40vh', height: '40vh' }}> {/* Set the height of the parent container */}

            <ResponsiveContainer width="100%" height="100%">
                <LineChart
                    data={[...list_colony_data.military_list]}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    
                >
                    {/* <CartesianGrid strokeDasharray="3 3" /> */}
                    <XAxis/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey={"total_military_personnel"} stroke="#6AB672" activeDot={{ r: 1 }} dot={false} isAnimationActive={true} animationDuration={100} />
                    <Line type="monotone" dataKey={"total_active_troops"} stroke="#778ED7" activeDot={{ r: 1 }} dot={false} isAnimationActive={true} animationDuration={100}/>
                    <Line type="monotone" dataKey={"total_reserved_manpower"} stroke="#B4BF6F" activeDot={{ r: 1 }} dot={false} isAnimationActive={true} animationDuration={100}/>
                    <Line type="monotone" dataKey={"total_reserved_troops"} stroke="#BE70DA" activeDot={{ r: 1 }} dot={false} isAnimationActive={true} animationDuration={100}/>
                    <Line type="monotone" dataKey={"total_troops_killed"} stroke="#DB4848" activeDot={{ r: 1 }} dot={false} isAnimationActive={true} animationDuration={100}/>
                </LineChart>
            </ResponsiveContainer>
            </div>

        );
    }

    return (
        <div className="text-white">
            <h1 className="font-bold">Military:</h1>
            {show_military_graphs()}
        </div>
    )
    
    };