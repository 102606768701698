import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


export default function ColonyViewMain({colony_data, previous_colony_data, list_colony_data}){






const show_tile_graph = () => {
    return (
        <div style={{ width:'30vh', height: '30vh' }}> {/* Set the height of the parent container */}

        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={[...list_colony_data.tile_count_list]}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis/>
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={"tile_count"} stroke="#6AB672" activeDot={{ r: 1 }} />
                
            </LineChart>

        </ResponsiveContainer>
        </div>

    );
}

return (
    <div className="text-white">
        <h1>Main:</h1>
        <h1>Colony Tiles: {colony_data.total_tile_count_raw}</h1>
        {show_tile_graph()}
    </div>
)

};