import { useState } from "react"

export default function SliderInput({handle_func, check_disable_func}){
    const [sliderValue, setSliderValue] = useState(985);

    const handle_input_change = (event) => {
        if(check_disable_func() == false){
            setSliderValue(event.target.value);
            handle_func(event.target.value);
        }
        
    }

    return (
        <div className="mx-1">
            
            <label for="sim-speed" className="text-white block text-sm font-medium text-gray-900 dark:text-white">Speed:</label>
            <input id="sim-speed" onChange={handle_input_change} type="range" min="0" max="1000" value={sliderValue} step="5" className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"></input>
        </div>

    )
}