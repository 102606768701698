

// take in a function...
export default function CloseButton({close_function, overlay_id}) {

  const handleClick = () => {
    close_function(overlay_id);
  };

  return(
        <button className="absolute bg-red-500 rounded p-1 h-full  aspect-w-1 right-2 top-0 text-white flex items-center" onClick={handleClick}>
        Close
      </button>
  )

}

