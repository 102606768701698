import { forwardRef, useImperativeHandle, useState, useRef } from "react"
import ColonyOverlay from "./ColonyOverlay"
import NewSimulationOverlay from "./NewSimulationOverlay";
import NewColonyViewOverlay from "./NewColonyViewOverlay";
import EditColonyBordersOverlay from "./EditColonyBordersOverlay";
import MapModeOverlay from "./MapModeOverlay";
import StatsOverlay from "./StatsOverlay";


const OverlayComponents = forwardRef((props, ref) =>{
    
    const [overlayComponents, setOverlayComponents] = useState([]);

    


    useImperativeHandle(ref, () => {
        return{
            add_colonyOverlay_func: add_ColonyOverlay,
            add_NewSimulationOverlay_func: add_NewSimulationOverlay,
            add_NewColonyViewOverlay_func: add_NewColonyViewOverlay,
            close_all_overlays_func: close_all_overlays,
            add_EditColonyBordersOverlay_func: add_EditColonyBordersOverlay,
            add_MapModeOverlay_func: add_MapModeOverlay,
            add_StatsOverlay_func: add_StatsOverlay
        }
    });

    const remove_overlay_with_id = (idToRemove) => {
        setOverlayComponents(prevComponents => prevComponents.filter(({ id }) => id !== idToRemove));
    }

    const check_if_create_colony_overlay_open = () => {
        for (const overlay of overlayComponents) {
            if (overlay.component.type.name == "AddColonyOverlay") {
                return true;
            }
        }
        return false;
    }

    /**
     * param: colony -> a specific colony (obj) for the overlay to display
     */
    const add_ColonyOverlay = (place_colony_clicked_func, new_colony_input_updated_func) => {
        if(check_if_create_colony_overlay_open() == false){
            const simulation_container = document.getElementById("simulation_container")
            const overlay_id = Date.now();
            const newColonyOverlay = <ColonyOverlay width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} place_colony_clicked_func={place_colony_clicked_func} new_colony_input_updated_func={new_colony_input_updated_func} remove_function={remove_overlay_with_id} overlay_id={overlay_id} component_type={"ColonyOverlay"}/>
            setOverlayComponents([...overlayComponents, {id: overlay_id, component: newColonyOverlay}])
        }
    }

    const close_all_overlays = () => {
        setOverlayComponents([]);
    }

    const add_NewSimulationOverlay = (show_close_button) => {
        const simulation_container = document.getElementById("simulation_container")
        const overlay_id = Date.now();
        const newNewSimulationOverlay = <NewSimulationOverlay handle_function={props.overlay_functions.handle_create_new_simulation_func} width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} remove_function={remove_overlay_with_id} overlay_id={overlay_id} show_close_button={show_close_button} />
        setOverlayComponents([...overlayComponents, {id: overlay_id, component: newNewSimulationOverlay}])
    }   

    const add_NewColonyViewOverlay = (colony_id) => {
        const simulation_container = document.getElementById("simulation_container")
        const overlay_id = Date.now();
        const newColonyViewOverlay = <NewColonyViewOverlay handle_function={props.overlay_functions.handle_update_colony_view_overlay_func} 
            width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} 
            remove_function={remove_overlay_with_id} overlay_id={overlay_id} colony_id={colony_id} />
      
        setOverlayComponents([...overlayComponents, {id: overlay_id, component: newColonyViewOverlay}])
    }


    const add_EditColonyBordersOverlay = (list_of_colonies,handle_function) => {
        const simulation_container = document.getElementById("simulation_container")
        const overlay_id = Date.now();
        const newEditColonyBordersOverlay = <EditColonyBordersOverlay handle_function={handle_function} 
            width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} 
            remove_function={remove_overlay_with_id} overlay_id={overlay_id} list_of_colonies={list_of_colonies} />
      
        setOverlayComponents([...overlayComponents, {id: overlay_id, component: newEditColonyBordersOverlay}])
    }

    const add_MapModeOverlay = (map_mode_functions) => {
        const simulation_container = document.getElementById("simulation_container")
        const overlay_id = Date.now();
        const newMapModeOverlay = <MapModeOverlay map_mode_functions={map_mode_functions} 
            width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} 
            remove_function={remove_overlay_with_id} overlay_id={overlay_id} />
      
        setOverlayComponents([...overlayComponents, {id: overlay_id, component: newMapModeOverlay}])
    }

    const add_StatsOverlay = (update_function) => {
        const simulation_container = document.getElementById("simulation_container")
        const overlay_id = Date.now();
        const new_StatsOverlay = <StatsOverlay update_function={update_function} 
            width_bound={simulation_container.offsetWidth} height_bound={simulation_container.offsetHeight} 
            remove_function={remove_overlay_with_id} overlay_id={overlay_id} />
      
        setOverlayComponents([...overlayComponents, {id: overlay_id, component: new_StatsOverlay}])
    }



    return (
        <>
        {overlayComponents.map(({ id, component }) => (
                <div key={id}>
                    {component}
                </div>
            ))}
        
        </>
    )
});

export default OverlayComponents;