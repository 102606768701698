import Draggable, {DraggableCore} from 'react-draggable';
import CloseButton from '../basic/CloseButton';
import { useEffect, useRef, useState } from 'react';
import ColonyViewMain from './colony_view_components/ColonyViewMain';
import ColonyViewPopulation from './colony_view_components/ColonyViewPopulation';
import ColonyViewMilitary from './colony_view_components/ColonyViewMilitary';
import ColonyViewIndustry from './colony_view_components/ColonyViewIndustry';


// props = canvas_width, canvas_height
export default function NewColonyViewOverlay({width_bound, height_bound, remove_function, overlay_id, handle_function, colony_id}) {
    const [bounds, setBounds] = useState({left: 0, top: 0, right: width_bound , bottom: height_bound})
    const [default_position, setDefaultPosition] = useState({x: 0, y: 0})
    const [colonyData, setColonyData] = useState({})
    const [previousColonyData, setPreviousColonyData] = useState({})
    const [listColonyData, setListColonyData] = useState({
        population_list: [],
        military_list: [],
        industry_list: [],
        tile_count_list: [],
    }); // holds last 100 colony data requests (mainly for graphing)
    const currentCDRef = useRef({});

    const list_length = useRef(0);



    // var previous_colony_data = {};


    const COLONY_VIEW_OVERLAY_SECTIONS = {
        MAIN: "main",
        POPULATION: "population",
        INDUSTRY: "industry",
        MILITARY: "military",
    }

    const colonyIdRef = useRef(colony_id);
    const [colonyViewSection, setColonyViewSection] = useState(COLONY_VIEW_OVERLAY_SECTIONS.MAIN); // start on colony section


    const add_colony_data_to_list = (data) => {
        if (data.population_raw === undefined) {
            return;
        }

        const new_pop_insert = {population: data.population_raw}
        // const new_total_military_personnel_insert = {total_military_personnel: data.total_military_personnel_raw}
        // const new_total_active_troops_insert = {total_active_troops: data.total_active_troops_raw}
        // const total_reserved_troops_raw = {total_reserved_troops: data.total_reserved_troops_raw}
        // const total_reserved_manpower_raw = {total_reserved_manpower: data.total_reserved_manpower_raw}
        // const total_troops_killed_raw = {total_troops_killed: data.total_troops_killed_raw}
        
        const new_military_insert = {
            total_military_personnel: data.total_military_personnel_raw,
            total_active_troops: data.total_active_troops_raw,
            total_reserved_troops: data.total_reserved_troops_raw,
            total_reserved_manpower: data.total_reserved_manpower_raw,
            total_troops_killed: data.total_troops_killed_raw
        }

        const new_industry_insert = {
            military_supplies: data.military_supplies_raw,
            military_food_supplies: data.military_food_supplies_raw,
            shipbuilding_supplies: data.shipbuilding_supplies_raw,
        }

        const new_tile_count_insert = {
            tile_count: data.total_tile_count_raw
        }

        list_length.current += 1;
        if (list_length.current > 100) {
            setListColonyData( previousData => ({
                ...previousData,
                population_list: [...previousData.population_list.slice(1), new_pop_insert],
                military_list: [...previousData.military_list.slice(1), new_military_insert],
                industry_list: [...previousData.industry_list.slice(1), new_industry_insert],
                tile_count_list: [...previousData.tile_count_list.slice(1), new_tile_count_insert],
            }))
        }else{
            setListColonyData( previousData => ({
                ...previousData,
                population_list: [...previousData.population_list, new_pop_insert],
                military_list: [...previousData.military_list, new_military_insert],
                industry_list: [...previousData.industry_list, new_industry_insert],
                tile_count_list: [...previousData.tile_count_list, new_tile_count_insert],
            }))
        }
        
        
    }

    const overlay_ref = useRef(null)


    const update_colony_data = async () => {
        setPreviousColonyData(currentCDRef.current);

        try{
            // get new colony data, and update current text
            let data = await handle_function(colonyIdRef.current);
            if(data.sim_running == true){
                // only add to lists if the simulation is running
                add_colony_data_to_list(data);
            }

            setColonyData(data);
            currentCDRef.current = data;
        }catch(error){
            console.log(`Error updating colony overlay: ${error}`);
        }

    }
    useEffect(() => {
    }, [colonyData]); 


    useEffect( () => {
        if(overlay_ref.current){
            // subtracting the width/height of this overlay, so the bounds work properly
            const rect = overlay_ref.current.getBoundingClientRect();
            setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
            setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
            update_colony_data();
            
            const interval_id = setInterval(update_colony_data, 1000);
            return () => clearInterval(interval_id);

        }
    },[]);

    const update_bounds = () => {
        const rect = overlay_ref.current.getBoundingClientRect();
        // setDefaultPosition({x: (width_bound-rect.width)/2, y: (height_bound-rect.height)/2})
        setBounds({left: 0, top: 0, right: (width_bound-rect.width), bottom: (height_bound-rect.height)})
    }
   
    useEffect( () => {
        update_bounds();

    },[colonyViewSection]);

    const handle_drag = (event, data) => {
        default_position.x = data.x;
        default_position.y = data.y;
    }

    const handle_main_clicked = () => {
        setColonyViewSection(COLONY_VIEW_OVERLAY_SECTIONS.MAIN);
    }
    const handle_population_clicked = () => {
        setColonyViewSection(COLONY_VIEW_OVERLAY_SECTIONS.POPULATION);
    }
    const handle_military_clicked = () => {
        setColonyViewSection(COLONY_VIEW_OVERLAY_SECTIONS.MILITARY);
    }
    const handle_industry_clicked = () => {
        setColonyViewSection(COLONY_VIEW_OVERLAY_SECTIONS.INDUSTRY);
    }

   

    return(
    <div className=" bg-black" >
        <Draggable
            bounds={bounds}
            axis="both"
            handle=".handle"
            defaultPosition={{x: 0, y: 0}}
            position={default_position}
            grid={[1, 1]}
            scale={1}
            // onStart={handleStart} onDrag={handleDrag}onStop={handleStop}
            onDrag={handle_drag}
            >
            <div className="absolute bg-blue  " ref={overlay_ref}>
                <div className="relative handle bg-white h-5 opacity-95">
                    <CloseButton close_function={remove_function} overlay_id={overlay_id}></CloseButton>
                </div>
                <div className="bg-slate-700 opacity-95 ">
                    { (colonyData )  && (
                        <div>
                            <h1 className="text-2xl text-white font-bold px-2" >{colonyData.name}</h1>
                            <div className='flex'>
                                <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                                    onClick={handle_main_clicked}>
                                    Colony
                                </button>
                                <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                                    onClick={handle_population_clicked}>
                                    Population
                                </button>
                                <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                                    onClick={handle_military_clicked}>
                                    Military
                                </button>
                                <button className='flex-auto m-1 bg-white rounded px-1 font-bold text-slate-800'
                                    onClick={handle_industry_clicked}>
                                    Industry
                                </button>


                            </div>
                        </div>

                        
                    )}

                    <div className="flex flex-col items-center gap-6 m-5">
                        <div className="relative w-full min-w-[200px] ">
                            {/* MAIN */}
                            {(colonyData && colonyViewSection === COLONY_VIEW_OVERLAY_SECTIONS.MAIN) && (
                                <div>
                                    <ColonyViewMain colony_data={colonyData} previous_colony_data={previousColonyData} list_colony_data={listColonyData}></ColonyViewMain>
                                </div>
                            )}

                            {/* POPULATION */}
                            {(colonyData && colonyViewSection === COLONY_VIEW_OVERLAY_SECTIONS.POPULATION) && (
                                <div>
                                    <ColonyViewPopulation colony_data={colonyData} previous_colony_data={previousColonyData} list_colony_data={listColonyData}></ColonyViewPopulation>
                                </div>
                            )}

                            {/* Military */}
                            {(colonyData && colonyViewSection === COLONY_VIEW_OVERLAY_SECTIONS.MILITARY) && (
                                <div>
                                    <ColonyViewMilitary colony_data={colonyData} previous_colony_data={previousColonyData} list_colony_data={listColonyData}></ColonyViewMilitary>
                                </div>
                            )}

                             {/* Industry */}
                             {(colonyData && colonyViewSection === COLONY_VIEW_OVERLAY_SECTIONS.INDUSTRY) && (
                                <div>
                                    <ColonyViewIndustry colony_data={colonyData} previous_colony_data={previousColonyData} list_colony_data={listColonyData}></ColonyViewIndustry>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
        
      </div>    
    );

}