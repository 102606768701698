export default function ExpandedButton(props){
    const handle_click = () => {
        if(props.check_disable_func() == false){
            props.function_call()
        }
            
        
    }

    return(
        <button className="bg-slate-950 text-slate-100 mr-2 px-3" onClick={handle_click} > {props.text} </button>
    )
}